// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-handle-payment-status-tsx": () => import("./../src/pages/handlePaymentStatus.tsx" /* webpackChunkName: "component---src-pages-handle-payment-status-tsx" */),
  "component---src-pages-handle-response-tsx": () => import("./../src/pages/handle-response.tsx" /* webpackChunkName: "component---src-pages-handle-response-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-privacy-policy-tsx": () => import("./../src/pages/mobile-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-mobile-privacy-policy-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pay-emi-tsx": () => import("./../src/pages/pay-emi.tsx" /* webpackChunkName: "component---src-pages-pay-emi-tsx" */),
  "component---src-pages-personal-loan-in-ahmedabad-tsx": () => import("./../src/pages/personal-loan-in-ahmedabad.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-ahmedabad-tsx" */),
  "component---src-pages-personal-loan-in-bangalore-tsx": () => import("./../src/pages/personal-loan-in-bangalore.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-bangalore-tsx" */),
  "component---src-pages-personal-loan-in-bhopal-tsx": () => import("./../src/pages/personal-loan-in-bhopal.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-bhopal-tsx" */),
  "component---src-pages-personal-loan-in-chandigarh-tsx": () => import("./../src/pages/personal-loan-in-chandigarh.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-chandigarh-tsx" */),
  "component---src-pages-personal-loan-in-chennai-tsx": () => import("./../src/pages/personal-loan-in-chennai.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-chennai-tsx" */),
  "component---src-pages-personal-loan-in-ernakulam-tsx": () => import("./../src/pages/personal-loan-in-ernakulam.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-ernakulam-tsx" */),
  "component---src-pages-personal-loan-in-gurgaon-tsx": () => import("./../src/pages/personal-loan-in-gurgaon.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-gurgaon-tsx" */),
  "component---src-pages-personal-loan-in-hyderabad-tsx": () => import("./../src/pages/personal-loan-in-hyderabad.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-hyderabad-tsx" */),
  "component---src-pages-personal-loan-in-indore-tsx": () => import("./../src/pages/personal-loan-in-indore.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-indore-tsx" */),
  "component---src-pages-personal-loan-in-jaipur-tsx": () => import("./../src/pages/personal-loan-in-jaipur.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-jaipur-tsx" */),
  "component---src-pages-personal-loan-in-mumbai-tsx": () => import("./../src/pages/personal-loan-in-mumbai.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-mumbai-tsx" */),
  "component---src-pages-personal-loan-in-mysore-tsx": () => import("./../src/pages/personal-loan-in-mysore.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-mysore-tsx" */),
  "component---src-pages-personal-loan-in-nagpur-tsx": () => import("./../src/pages/personal-loan-in-nagpur.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-nagpur-tsx" */),
  "component---src-pages-personal-loan-in-navi-mumbai-tsx": () => import("./../src/pages/personal-loan-in-navi-mumbai.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-navi-mumbai-tsx" */),
  "component---src-pages-personal-loan-in-new-delhi-tsx": () => import("./../src/pages/personal-loan-in-new-delhi.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-new-delhi-tsx" */),
  "component---src-pages-personal-loan-in-noida-tsx": () => import("./../src/pages/personal-loan-in-noida.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-noida-tsx" */),
  "component---src-pages-personal-loan-in-pune-tsx": () => import("./../src/pages/personal-loan-in-pune.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-pune-tsx" */),
  "component---src-pages-personal-loan-in-surat-tsx": () => import("./../src/pages/personal-loan-in-surat.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-surat-tsx" */),
  "component---src-pages-personal-loan-in-thane-tsx": () => import("./../src/pages/personal-loan-in-thane.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-thane-tsx" */),
  "component---src-pages-personal-loan-in-vadodara-tsx": () => import("./../src/pages/personal-loan-in-vadodara.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-vadodara-tsx" */),
  "component---src-pages-personal-loan-in-vizag-tsx": () => import("./../src/pages/personal-loan-in-vizag.tsx" /* webpackChunkName: "component---src-pages-personal-loan-in-vizag-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-refund-and-cancellation-tsx": () => import("./../src/pages/refund-and-cancellation.tsx" /* webpackChunkName: "component---src-pages-refund-and-cancellation-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-zincash-tsx": () => import("./../src/pages/zincash.tsx" /* webpackChunkName: "component---src-pages-zincash-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

